import React, { FC, useState } from 'react';

import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { meshGatewayClient } from '../../../apollo/client';
import { useOrgId } from '../../../apollo/local-state';
import { GetLeaguesCoordinators, GetLeaguesCoordinatorsVariables } from '../../../graphql-types/GetLeaguesCoordinators';
import { useDebounce } from '../../../hooks/use-debounce';
import CoordinatorsTable from '../../coordinators-table/coordinators-table';
import TextInput from '../../input/input';
import InviteStatusFilter from '../../invite-status-filter/invite-status-filter';
import Panel from '../../panel/panel';
import { useControlledQuery } from '../../table-controls/table-controls';
import { PageMaxWidth } from '../../util-components/util-components';
import { GET_LEAGUES_COORDINATORS } from '../leagues-queries';
import * as styles from '../leagues.module.less';

interface GetLeaguesCoordinatorsVariablesWithPagination extends GetLeaguesCoordinatorsVariables {
  offset: number;
  limit: number;
}
const LeaguesCoordinators: FC = () => {
  const { t } = useTranslation();

  const [search, setSearch] = useState('');
  const [inviteStatus, setInviteStatus] = useState('');
  const searchFilter = useDebounce(search, 500);

  const orgId = useOrgId();

  const getSelectedFilters = () => {
    return {
      entityIds: [orgId],
      includeChildren: true,
      ...(searchFilter && { fullName: searchFilter }),
      ...(inviteStatus && { roleStates: [inviteStatus] }),
    };
  };
  const {
    data: coordinators,
    controlProps,
    loading,
  } = useControlledQuery<GetLeaguesCoordinators, GetLeaguesCoordinatorsVariablesWithPagination>(
    GET_LEAGUES_COORDINATORS,
    {
      client: meshGatewayClient,
      getTotalItems: (area: GetLeaguesCoordinators) => area.resourcesByEntityId?.total ?? 0,
      notifyOnNetworkStatusChange: true,
      transformVariables: (v: GetLeaguesCoordinatorsVariablesWithPagination) => {
        const { limit, offset } = v;
        const filters = getSelectedFilters();

        return {
          filter: { ...filters },
          pageArgs: { limit, skip: offset },
        };
      },
    },
  );
  return (
    <PageMaxWidth>
      <Panel>
        <Grid container alignItems={'center'}>
          <div className={styles.searchWrapper}>
            <TextInput
              placeholder={t('search name')}
              value={search}
              onChange={(t) => setSearch(t?.target.value)}
              disableUnderline
              outlined
              fullWidth
              onClearClick={search ? () => setSearch('') : undefined}
            />
          </div>
          <InviteStatusFilter status={inviteStatus} setStatus={setInviteStatus} />
        </Grid>
      </Panel>
      <Panel>
        <CoordinatorsTable
          data={coordinators?.resourcesByEntityId?.results}
          type={'league'}
          controls={controlProps}
          loading={loading}
        />
      </Panel>
    </PageMaxWidth>
  );
};

export default LeaguesCoordinators;
